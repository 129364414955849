import { useContext, useEffect, useRef, useState } from "react";
import useAPIRequest from "../simple/useAPIRequest";
import createFilterText from "../../utils/CreateFilterText";
import UiContext from "../../store/ui-context";

const useSelectorDialogControl = ({
  apiName,
  orderBy,
  orderMethod,
  additionalParams,
  additionalFilter,
  take,
  visible,
}) => {
  const uiContext = useContext(UiContext);
  const [paging, setPaging] = useState({
    page: 1,
    take: take ?? 10,
    filter: "",
    total: 1,
    ...additionalParams,
  });
  const toast = useRef(null);
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});

  const { requestGet, requestGetOne, requestPost, requestDelete, loading } =
    useAPIRequest({
      url: `api/${apiName}`,
    });

  const reloadListData = async ({ page, take, filter }) => {
    if (loading) return;

    setListData([]);

    let newFilter = filter ?? "";
    if (additionalFilter && additionalFilter.length > 0) {
      if (newFilter.length > 0) {
        newFilter += ";";
      }
      newFilter += additionalFilter;
    }
    await requestGet({
      apiUrl: `data`,
      params: {
        page: page,
        take: take,
        filter: newFilter,
        order: orderBy,
        order_method: orderMethod,
        ...additionalParams,
      },
      onSuccess: ({ pagination, message, data }) => {
        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
          filter: pagination["filter"],
        });
        setListData(data);
      },
      onError: ({ message, data }) => {
        // console.log("ERROR", message);
        uiContext.showErrorMsg("Error", message);
      },
    });

    return;
  };

  useEffect(() => {
    if (visible) {
      reloadListData({ page: 1, take: take ?? 10, filter: "" });
    }
  }, [visible]);

  const handleOnFilter = (filterMap) => {
    const filterString = createFilterText(filterMap);
    setPaging({ ...paging, filter: filterString });
    reloadListData({
      page: paging.page,
      take: paging.take,
      filter: filterString,
    });
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadListData({ page: newPage, take: newTake, filter: paging.filter });
  };

  const refineValue = (newValue) => {
    if (newValue.length > 0) {
      let nullCount = 0;
      for (const v of newValue) {
        if (v === null) {
          nullCount++;
        }
      }
      if (nullCount === newValue.length) {
        return [];
      }
    }
    return newValue;
  };

  const handleSelectionChange = (values) => {
    if (Array.isArray(values)) {
      setSelectedRows([...values]);
    } else if (typeof values === "object") {
      setSelectedRows([values]);
    } else {
      setSelectedRows([]);
    }
  };

  return {
    // UIs
    toast,
    // States
    listData,
    paging,
    setPaging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    // Methods
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  };
};

export default useSelectorDialogControl;
