const SalesJournalConfig = {
  master: {
    title: "Sales Journal",
    labelNew: "*BON BARU*",
    apiName: "sales/sales",
    orderBy: "id",
    orderMethod: "DESC",
    additionalParams: {
      // add_params
      with_customer: 1,
      with_bc: 1,
    },
    additionalFilter: "payed:=:1",
  },
  filters: [
    {
      name: "Transaction Date",
      field: "transaction_date",
      type: "date_range",
      format: "dd/MM/yyyy",
      prime_format: "dd/mm/yy",
      default_value: [new Date(), new Date()],
      range: true,
    },
  ],
  columns: [
    // add_configs
    {
      name: "Action",
      field: "preview",
      type: "text_plain",
      table: true,
      align: "center",
      // filter: true,
      // editor: true,
    },
    {
      name: "Invoice",
      field: "number",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Customer",
      field: "data_customer.name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Grand Total",
      field: "grand_total",
      type: "number_plain",
      align: "right",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Note",
      field: "notes",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    // {
    //   name: "Dari Department",
    //   field: "department_dari_id",
    //   type: "object",
    //   object_name: "data_department_dari",
    //   object_field: "nama",
    //   editor: true,
    //   table: true,
    //   filter: true,
    // },
    // {
    //   name: "Ke Department",
    //   field: "department_kepada_id",
    //   type: "object",
    //   object_name: "data_department_kepada",
    //   object_field: "nama",
    //   editor: true,
    //   table: true,
    //   filter: true,
    // },
    // {
    //   name: "Nomor NPPB",
    //   field: "nomor_nppb",
    //   type: "text_plain",
    //   table: true,
    //   filter: true,
    //   editor: true,
    // },
    // {
    //   name: "Tanggal NPPB",
    //   field: "tanggal_nppb",
    //   type: "date",
    //   align: "left",
    //   table: true,
    //   editor: true,
    //   format: "dd/MM/yyyy",
    //   prime_format: "d MM yy",
    //   showTime: 1,
    // },
    // {
    //   name: "Tanggal Terima",
    //   field: "tanggal_terima",
    //   type: "date",
    //   align: "left",
    //   table: true,
    //   editor: true,
    //   // default_value: new Date(),
    //   format: "dd/MM/yyyy",
    //   prime_format: "d MM yy",
    //   showTime: 1,
    // },
    // {
    //   name: "Department Terima Dari",
    //   field: "department_terima_dari_id",
    //   type: "object",
    //   object_name: "data_department_terima_dari",
    //   object_field: "nama",
    //   editor: true,
    //   table: true,
    //   filter: true,
    // },
    // {
    //   name: "Gudang",
    //   field: "gudang_id",
    //   type: "object",
    //   align: "left",
    //   object_name: "data_gudang",
    //   object_field: "nama",
    //   editor: true,
    //   table: true,
    //   filter: true,
    // },
    // {
    //   name: "Jenis Pemasukan",
    //   field: "jenis_pemasukan_id",
    //   type: "object",
    //   object_name: "data_jenis_pemasukan",
    //   object_field: "nama",
    //   editor: true,
    //   table: true,
    //   filter: true,
    // },
  ],
};

export default SalesJournalConfig;
