import { Toast } from "primereact/toast";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { Paginator } from "primereact/paginator";

import { format, parse } from "date-fns";
import configs from "./SalesJournalConfig";
import useMDArsipPageControl from "../../../../custom_hooks/complex/useMDArsipPageControl";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";
import PrintSalesReceiptDialog from "../../../../dialogs/PrintSalesReceiptDialog";
import UiContext from "../../../../store/ui-context";
import SalesPreviewDialog from "./SalesPreviewDialog";
import CustomerSelectorDialog from "../../../../dialogs/selectors/CustomerSelectorDialog";
import SalesChangeDateDialog from "./SalesChangeDateDialog";

const customFilters = [];

export const generateTabLabel = (row) => {
  return `Nomor NPB: ${row.nomor_npb}`;
};

const newTab = false;

const additionalParams = configs.master.additionalParams;
const orderBy = configs.master.orderBy;
const orderMethod = configs.master.orderMethod;
const apiName = configs.master.apiName;
const title = configs.master.title;
const additionalFilter = configs.master.additionalFilter;

const columns = generateTableConfigs(configs.columns);

const SalesJournalPage = (props) => {
  const { addNewEditor } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const currentUrl = location.pathname;

  const uiContext = useContext(UiContext);

  const [visiblePrintReceiptDialog, setVisiblePrintReceiptDialog] =
    useState(false);
  const [visibleSalesPreviewDialog, setVisibleSalesPreviewDialog] =
    useState(false);
  const [visibleChangeDateDialog, setVisibleChangeDateDialog] = useState(false);
  const [selectedSales, setSelectedSales] = useState({});

  const [totalAll, setTotalAll] = useState({ customer: 0, count: 0, total: 0 });
  const [totalActive, setTotalActive] = useState({
    customer: 0,
    count: 0,
    total: 0,
  });

  const {
    // UIs
    toast,
    // states
    paging,
    loading,
    listData,
    setListData,
    selectedRows,
    filter,
    setFilter,
    //methods
    elements,
    generateSelectorDialog,
    handleReload,
    handleOnPage,
    // handleAddNew,
    handleDelete,
    handleDeleteMultiple,
    handleEdit,
    handleSelectionChange,
    requestGet,
    requestPost,
    requestDelete,
    requestGetOne,
    handleFilterChange,
    handleMultipleFilterChange,
  } = useMDArsipPageControl({
    title,
    apiName,
    additionalParams,
    orderBy,
    orderMethod,
    addNewEditor,
    generateTabLabel,
    configFilters: configs.filters,
    customFilters,
    additionalFilter,
    defaultTake: 30,
  });

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  useEffect(() => {
    updateTotal(listData);
  }, [listData]);

  generateSelectorDialog({
    field: "customer_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <CustomerSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const setActive = async ({ id, active }) => {
    return await requestPost({
      fullUrl: "api/sales/sales/changeactive",
      body: { ids: [id], active },
      onSuccess: ({ data }) => {
        if (data) {
          uiContext.showSuccessMsg("Success", "Sales status changed");
          const newList = listData.map((row) => {
            if (row.id === id) {
              return { ...row, active: active };
            }
            return row;
          });
          setListData(newList);
          updateTotal(newList);
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const updateTotal = (list) => {
    let _totalAll = { customer: 0, count: 0, total: 0 };
    let _totalActive = { customer: 0, count: 0, total: 0 };

    const listAllCustomerId = [];
    const listActiveCustomerId = [];

    list.forEach((row) => {
      const customerId = row.customer_id;
      if (customerId && !listAllCustomerId.includes(customerId)) {
        listAllCustomerId.push(customerId);
      }

      // _totalAll.customer += 1;
      _totalAll.count += 1;
      _totalAll.total += row.grand_total;

      if (row.active > 0) {
        if (customerId && !listActiveCustomerId.includes(customerId)) {
          listActiveCustomerId.push(customerId);
        }

        // _totalActive.customer += 1;
        _totalActive.count += 1;
        _totalActive.total += row.grand_total;
      }
    });

    _totalAll.customer = listAllCustomerId.length;
    _totalActive.customer = listActiveCustomerId.length;

    setTotalAll(_totalAll);
    setTotalActive(_totalActive);
  };

  const handlePreview = async (row) => {
    await loadSales({ id: row.id });
    setVisibleSalesPreviewDialog(true);
  };

  const handlePrintReceipt = async (row) => {
    await loadSales({ id: row.id });
    setVisiblePrintReceiptDialog(true);
  };

  // const handleRefundSales = async (row) => {
  //   confirmDialog({
  //     message: "Are you sure you want refund this sales?",
  //     header: "Confirmation",
  //     icon: "pi pi-exclamation-triangle",
  //     accept: () => {
  //       refundSales({ id: row.id });
  //     },
  //   });
  // };

  const handleSwitchActive = (data) => {
    confirmDialog({
      message: "Are you sure you want to switch this sales status?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        setActive({ id: data.id, active: data.active > 0 ? 0 : 1 });
      },
    });
  };

  const customColumns = [
    {
      field: "preview",
      component: (rowData) => {
        return (
          <div className="row-center-center gap-2">
            {/* <Button
              icon="pi pi-undo"
              rounded
              outlined
              severity="danger"
              aria-label="Search"
              onClick={(e) => handleRefundSales(rowData)}
            /> */}
            <Button
              icon="pi pi-print"
              rounded
              outlined
              aria-label="Search"
              onClick={(e) => handlePrintReceipt(rowData)}
            />
            <Button
              icon="pi pi-eye"
              rounded
              outlined
              aria-label="Search"
              onClick={(e) => handlePreview(rowData)}
            />
            <Button
              className={rowData.active <= 0 ? `border-red-200` : ``}
              pt={{
                icon: {
                  className: rowData.active <= 0 ? `text-red-200` : ``,
                },
              }}
              icon={`pi ${rowData.active <= 0 ? "pi-circle" : "pi-check"}`}
              rounded
              severity={rowData.active <= 0 ? "danger" : "success"}
              outlined={rowData.active <= 0}
              aria-label="Search"
              onClick={(e) => handleSwitchActive(rowData)}
            />
          </div>
        );
      },
    },
    {
      field: "number",
      component: (rowData) => {
        const { number, transaction_date } = rowData;
        return (
          <div className="col-start-start">
            <span className="">{number}</span>
            <span className="text-sm text-left text-slate-400 font-semibold">
              {transaction_date}
            </span>
          </div>
        );
      },
    },
    {
      field: "data_customer.name",
      component: (rowData) => {
        const { data_customer } = rowData;
        let _name = "";
        let _code = "";

        if (data_customer) {
          const { name, code } = data_customer;
          _name = name ?? "";
          _code = code ?? "";
        }

        return (
          <div className="col-start-start">
            <span className="">{_name}</span>
            <span className="text-sm text-left text-slate-400 font-semibold">
              {_code}
            </span>
          </div>
        );
      },
    },
  ];

  const loadSales = async ({ id }) => {
    await requestGetOne({
      fullUrl: `/api/sales/sales/one/${id}`,

      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].no = i + 1;
          }
          setSelectedSales(data);
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const handleClickChangeDate = () => {
    setVisibleChangeDateDialog(true);
  };

  const handleChangeDateConfirm = async (date) => {
    if (date) {
      const newDateMysql = format(date, "yyyy-MM-dd HH:mm:ss");

      return await requestPost({
        fullUrl: "api/sales/sales/changetransactiondate",
        body: { id: selectedSales.id, transaction_date: newDateMysql },
        onSuccess: ({ data }) => {
          if (data) {
            uiContext.showSuccessMsg("Success", "Date changed");
            setVisibleChangeDateDialog(false);
            const newSelectedSales = {
              ...selectedSales,
              transaction_date: data.transaction_date,
            };
            setSelectedSales(newSelectedSales);
            setListData((prev) => {
              return prev.map((row) => {
                if (row.id === selectedSales.id) {
                  return newSelectedSales;
                }
                return row;
              });
            });
          }
        },
        onError: ({ message, data }) =>
          uiContext.showErrorMsg("Error", message),
      });
    }
  };

  const TotalLine = ({ prompt, value, bgColor, textColor, percent }) => {
    return (
      <div className="row-start-center w-100 gap-5 ">
        <div
          className={`row-end-center w-[13rem] h-[2.2rem] ${
            bgColor ?? "bg-slate-400"
          } px-3`}
        >
          <span className="text-sm text-white font-semibold tracking-wide">
            {prompt.toUpperCase()}
          </span>
        </div>
        <div className="row-start-center gap-4">
          <span
            className={`text-lg font-semibold ${textColor ? textColor : ""}`}
          >
            {value.toLocaleString("id-ID")}
          </span>
          {percent && (
            <span
              className={`text-lg font-semibold ${textColor ? textColor : ""}`}
            >
              ({percent.toLocaleString("id-ID", { maximumFractionDigits: 1 })}%)
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`col-start-start px-4 gap-4 w-100`}>
      <Toast ref={toast} />
      <ConfirmDialog />

      <SalesPreviewDialog
        data={selectedSales}
        onClickChangeDate={handleClickChangeDate}
        visible={visibleSalesPreviewDialog}
        onHide={(e) => {
          setVisibleSalesPreviewDialog(false);
        }}
      />

      <SalesChangeDateDialog
        data={selectedSales}
        onConfirm={handleChangeDateConfirm}
        visible={visibleChangeDateDialog}
        loading={loading}
        onHide={(e) => {
          setVisibleChangeDateDialog(false);
        }}
      />

      <PrintSalesReceiptDialog
        visible={visiblePrintReceiptDialog}
        onHide={(e) => {
          setVisiblePrintReceiptDialog(false);
        }}
        data={selectedSales}
      />

      <div></div>
      <div className="elevated-card w-100 gap-3 row-between-center">
        <div className="row-start-center gap-3">
          <div className="row-start-center">{elements.transaction_date}</div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={handleReload}
            rounded
            outlined
          />
        </div>
      </div>
      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            showControlButton={false}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={columns}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          />
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 30, 50, 100, 200]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>
      <div className="row-start-start w-100 gap-4">
        <div className="elevated-card w-100 col-start-start gap-4">
          <span className="font-semibold text-slate-500"> ALL TRANSACTION</span>
          <div className="col-start-start w-100 gap-3">
            <TotalLine prompt="Customer Count" value={totalAll.customer} />
            <TotalLine prompt="Transaction Count" value={totalAll.count} />
            <TotalLine prompt="Transaction Total" value={totalAll.total} />
          </div>
        </div>
        <div className="elevated-card w-100 col-start-start gap-4">
          <span className="font-semibold text-emerald-600">
            {" "}
            ACTIVE TRANSACTION
          </span>
          <div className="col-start-start w-100 gap-3">
            <TotalLine
              bgColor="bg-emerald-600"
              textColor="text-emerald-700"
              prompt="Customer Count"
              value={totalActive.customer}
              percent={(totalActive.customer / totalAll.customer) * 100}
            />
            <TotalLine
              bgColor="bg-emerald-600"
              textColor="text-emerald-700"
              prompt="Transaction Count"
              value={totalActive.count}
              percent={(totalActive.count / totalAll.count) * 100}
            />
            <TotalLine
              bgColor="bg-emerald-600"
              textColor="text-emerald-700"
              prompt="Transaction Total"
              value={totalActive.total}
              percent={(totalActive.total / totalAll.total) * 100}
            />
          </div>
        </div>
        <div className="elevated-card w-100 col-start-start gap-4">
          <span className="font-semibold text-red-500"> DIFFERENCE</span>
          <div className="col-start-start w-100 gap-3">
            <TotalLine
              bgColor="bg-red-500"
              textColor="text-red-500"
              prompt="Customer Count"
              value={totalAll.customer - totalActive.customer}
              percent={
                ((totalAll.customer - totalActive.customer) /
                  totalAll.customer) *
                100
              }
            />
            <TotalLine
              bgColor="bg-red-500"
              textColor="text-red-500"
              prompt="Transaction Count"
              value={totalAll.count - totalActive.count}
              percent={
                ((totalAll.count - totalActive.count) / totalAll.count) * 100
              }
            />
            <TotalLine
              bgColor="bg-red-500"
              textColor="text-red-500"
              prompt="Transaction Total"
              value={totalAll.total - totalActive.total}
              percent={
                ((totalAll.total - totalActive.total) / totalAll.total) * 100
              }
            />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default SalesJournalPage;
