import { useContext, useEffect, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import generateDefaultValue from "../../utils/GenerateDefaultValue";
import assignDataToMap from "../../utils/AssignDataToMap";
import generateEditorElement from "../../utils/GenerateEditorElement";
import useAPIRequest from "../simple/useAPIRequest";
import { format as dateFnsFormat, parse } from "date-fns";
import UiContext from "../../store/ui-context";

const useMDArsipPageControl = ({
  apiName,
  additionalParams,
  orderBy,
  orderMethod,
  configFilters,
  addNewEditor,
  generateTabLabel,
  customFilters,
  additionalFilter,
  defaultTake,
}) => {
  const uiContext = useContext(UiContext);
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [error, setError] = useState({});
  const [filter, setFilter] = useState(
    generateDefaultValue({ tableConfigs: configFilters })
  );

  const [paging, setPaging] = useState({
    page: 1,
    take: defaultTake ?? 10,
    filter: "",
    total: 0,
    ...additionalParams,
  });

  const { requestGet, requestDelete, requestPost, requestGetOne, loading } =
    useAPIRequest({
      url: `api/${apiName}`,
    });

  const generateFilterText = ({ configFilters, filter }) => {
    let filterArr = [];

    const keys = Object.keys(filter);

    for (const filterField of keys) {
      if (filterField.startsWith("data_")) {
        continue;
      }

      const filterValue = filter[filterField];
      const config = configFilters.find((el) => el.field === filterField);
      const type = config.type;

      switch (type) {
        case "object":
          filterArr.push(`${filterField}:=:${filterValue}`);
          break;
        case "date_range":
          let dateFrom = null,
            dateTo = null;
          if (filterValue.length > 0) {
            dateFrom = filterValue[0];
          }
          if (filterValue.length > 1) {
            dateTo = filterValue[1];
          }
          let dateFilter = "";
          if (dateFrom !== null && dateTo !== null) {
            dateFilter = `${filterField}:>=:${dateFnsFormat(
              dateFrom,
              "yyyy-MM-dd 00:00:00"
            )};${filterField}:<=:${dateFnsFormat(
              dateTo,
              "yyyy-MM-dd 23:59:59"
            )}`;
          }
          if (dateFrom !== null && dateTo === null) {
            dateFilter = `${filterField}:>=:${dateFnsFormat(
              dateFrom,
              "yyyy-MM-dd 00:00:00"
            )};${filterField}:<=:${dateFnsFormat(
              dateFrom,
              "yyyy-MM-dd 23:59:59"
            )}`;
          }
          filterArr.push(dateFilter);
          break;
      }
    }
    let resultFilter = filterArr.join(";");
    if (additionalParams && additionalParams.filter) {
      resultFilter += `;${additionalParams.filter}`;
    }

    return resultFilter;
  };

  const reloadListData = ({ page, take, filter }) => {
    if (loading) return;

    let newFilter = filter;
    if (additionalFilter && additionalFilter.length > 0) {
      if (newFilter.length > 0) {
        newFilter += ";" + additionalFilter;
      } else {
        newFilter += additionalFilter;
      }
    }
    requestGet({
      apiUrl: `data`,
      params: {
        page: page,
        take: take,
        filter: newFilter,
        order: orderBy,
        order_method: orderMethod,
        ...additionalParams,
      },
      onSuccess: ({ pagination, message, data }) => {
        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
          filter: pagination["filter"],
        });
        setListData(data);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });

    return;
  };

  const handleReload = async () => {
    setPaging({ ...paging, filter: "" });
    reloadListData({
      page: paging.page,
      take: paging.take,
      filter: generateFilterText({ configFilters, filter }),
    });
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadListData({
      page: newPage,
      take: newTake,
      filter: generateFilterText({ configFilters, filter }),
    });
  };

  const handleDelete = (row) => {
    const accept = async () => {
      if (loading) return;

      requestDelete({
        apiUrl: "delete",
        ids: [row.id],
        onError: ({ message, data }) =>
          uiContext.showErrorMsg("Error", message),
        onSuccess: ({ message, data }) => {
          const newListData = listData.filter((el) => el.id !== row.id);
          setListData(newListData);
          uiContext.showSuccessMsg("Success", message);
        },
      });
    };
    const reject = () => {};

    confirmDialog({
      message: "Menghapus 1 data, lanjutkan?",
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const handleDeleteMultiple = (row) => {
    const accept = async () => {
      if (loading) return;

      const listIds = selectedRows.map((el) => el.id);
      requestDelete({
        apiUrl: "delete",
        ids: listIds,
        onError: ({ message, data }) =>
          uiContext.showErrorMsg("Error", message),
        onSuccess: ({ message, data }) => {
          const newListData = listData.filter((el) => !listIds.includes(el.id));
          setListData(newListData);
          uiContext.showSuccessMsg("Success", message);
        },
      });
    };
    const reject = () => {};

    confirmDialog({
      message: `Menghapus ${selectedRows.length} data, lanjutkan?`,
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const handleEdit = (row) => {
    addNewEditor({
      ...row,
      label: generateTabLabel(row),
    });
  };

  const handleAddNew = (e) => {
    e.preventDefault();
    addNewEditor({
      id: 0,
    });
  };

  const handleSelectionChange = (values) => {
    if (values) {
      setSelectedRows([...values]);
    } else {
      setSelectedRows([]);
    }
  };

  // Filters control
  const handleFilterChange = (field, newValue) => {
    const newMap = { ...filter };
    if (!field.includes(".")) {
      newMap[field] = newValue;
    } else {
      assignDataToMap({ key: field, map: newMap, value: newValue });
    }
    setFilter(newMap);
  };

  const handleMultipleFilterChange = (mapsNewValue) => {
    const newMap = { ...filter };
    for (const mapNewValue of mapsNewValue) {
      newMap[mapNewValue.field] = mapNewValue.newValue;
    }
    setFilter(newMap);
  };

  const generateSelectorDialog = ({ field, componentFunction }) => {
    let fieldArr = [];
    if (field.includes(" ")) {
      fieldArr = field.split(" ");
    } else if (field.includes(",")) {
      fieldArr = field.split(",");
    } else {
      fieldArr.push(field);
    }
    for (const fieldSingle of fieldArr) {
      const config = configFilters.find(
        (element) => element.field === fieldSingle
      );
      if (config) {
        config.selectorGenerator = componentFunction;
      }
    }
  };

  //auto generate filter element
  const defaultProps = {
    value: filter,
    error: error,
    handleInputChange: handleFilterChange,
    handleMultipleInputChange: handleMultipleFilterChange,
  };

  const mapGeneratedElement = {};
  let firstElement = true;
  for (const config of configFilters) {
    let foundEl;
    for (const custEl of customFilters) {
      const { field, element } = custEl;
      if (config.field === field) {
        foundEl = element;
      }
    }
    if (foundEl) {
      mapGeneratedElement[config.field] = foundEl({
        ...defaultProps,
        autoFocus: firstElement,
        config: config,
      });
    } else {
      const generatedElement = generateEditorElement({
        ...defaultProps,
        autoFocus: firstElement,
        config: config,
      });
      if (generatedElement) {
        mapGeneratedElement[config.field] = generatedElement;
      }
    }
    if (firstElement) {
      firstElement = false;
    }
  }

  return {
    // UIs
    // states
    paging,
    setPaging,
    loading,
    listData,
    setListData,
    selectedRows,
    setSelectedRows,
    filter,
    setFilter,
    elements: mapGeneratedElement,
    //methods
    generateSelectorDialog,
    reloadListData,
    handleReload,
    handleOnPage,
    handleAddNew,
    handleDelete,
    handleDeleteMultiple,
    handleEdit,
    handleSelectionChange,
    requestGet,
    requestDelete,
    requestGetOne,
    requestPost,
    handleFilterChange,
    handleMultipleFilterChange,
  };
};

export default useMDArsipPageControl;
