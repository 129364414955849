import {
  FaAddressBook,
  FaAngleUp,
  FaArrowRightArrowLeft,
  FaBlackTie,
  FaBook,
  FaBullseye,
  FaCakeCandles,
  FaCalendarCheck,
  FaCashRegister,
  FaChartBar,
  FaChartSimple,
  FaCircleLeft,
  FaCircleRight,
  FaCreditCard,
  FaDatabase,
  FaDisplay,
  FaFaceSmile,
  FaGears,
  FaGroupArrowsRotate,
  FaHouse,
  FaImages,
  FaLayerGroup,
  FaNewspaper,
  FaReceipt,
  FaSackDollar,
  FaSquareCheck,
  FaTags,
  FaUsers,
  FaUserTie,
  FaWarehouse,
  FaWaveSquare,
} from "react-icons/fa6";

const MenuAdmin = [
  {
    label: "Setting",
    icon: <FaGears />,
    to: "developer/setting",
    children: [],
  },
  {
    label: "Transaction",
    to: "developer/transaction",
    icon: <FaReceipt />,
  },
];

export default MenuAdmin;
