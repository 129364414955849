import { Button } from "primereact/button";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import { useContext, useEffect, useState } from "react";
import UiContext from "../../../../store/ui-context";
import { InputSwitch } from "primereact/inputswitch";

const ActivateDeveloperModeSetting = (props) => {
  const { setting, setSetting } = props;

  const uiContext = useContext(UiContext);
  const { loading, requestGet, requestPost } = useAPIRequest();

  const switchDeveloperMode = async () => {
    const value = setting.value_number;
    const switchedValue = value > 0 ? 0 : 1;
    const newSetting = { ...setting, value_number: switchedValue };

    return requestPost({
      fullUrl: `/api/setting/save`,
      body: newSetting,
      onSuccess: ({ pagination, message, data }) => {
        setSetting(data);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  return (
    <>
      <div className="col-start-start w-[25rem] hover:bg-indigo-50 p-3 gap-4">
        <div className="col-start-start">
          <h1 className="font-bold text-slate-600">Developer Mode</h1>
          <h2 className="font-semibold text-sm text-slate-600">
            Activate debug mode for developer, this will enable some feature
          </h2>
        </div>

        {setting && (
          <div className="row-start-center gap-3">
            <InputSwitch
              checked={setting.value_number > 0}
              onChange={switchDeveloperMode}
            />
            {loading && (
              <i
                className="pi pi-spin pi-spinner text-slate-400"
                style={{ fontSize: "1.2rem" }}
              ></i>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default ActivateDeveloperModeSetting;
