import classes from "./TableAutoComponent.module.css";
import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { format as dateFnsFormat, parse } from "date-fns";
import { Button } from "primereact/button";
import extractDataFromMap from "../../../utils/ExtractDataFromMap";
import generateTableAutoEditorElement from "../../../utils/GenerateTableAutoEditorElement";
// import { process.env.REACT_APP_HOST_URL } from "../../../configs/constants";

const TableAutoComponent = (props) => {
  const { editRowEnabled, onDoubleClick } = props;
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.onSelectionChange(selectedRows);
  }, [selectedRows]);

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="row-center-center">
        <Button
          disabled={editRowEnabled ? editRowEnabled(rowData) : false}
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          style={{ width: "40px", height: "40px" }}
          onClick={() => props.onEditRow(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          style={{ width: "40px", height: "40px" }}
          onClick={() => props.onDeleteRow(rowData)}
        />
      </div>
    );
  };

  return (
    <div
      className={`${classes.wrapper} ${
        props.className ?? ""
      } row-center-center gap-2 w-100`}
    >
      <DataTable
        className="w-100"
        showGridlines
        loading={props.loading}
        stripedRows
        editMode="cell"
        value={props.value}
        selection={selectedRows}
        onSelectionChange={(e) => setSelectedRows(e.value)}
        dataKey="id"
        // tableStyle={{ minWidth: props.minWidth ?? "50rem" }}
        onClick={(event) => {
          if (event.detail >= 2) {
            if (onDoubleClick) {
              onDoubleClick(selectedRows);
            }
          }
        }}
      >
        {/* checkbox column */}
        {selectedRows && (
          <Column
            key="#"
            selectionMode={props.singleMode ? "single" : "multiple"}
            headerStyle={{ width: "3rem" }}
          ></Column>
        )}

        {/* action column */}
        {props.showControlButton && (
          <Column
            key="action"
            header="Action"
            alignHeader={"center"}
            body={actionBodyTemplate}
          ></Column>
        )}

        {props.configs.map((config) => {
          let {
            editable,
            name,
            field,
            align,
            type,
            format,
            hidden,
            options,
            object_name,
            object_field,
          } = config;

          if (hidden) {
            return undefined;
          }

          if (!align) {
            align = "left";
          }

          let bodyTemplate;
          const editor = editable
            ? generateTableAutoEditorElement({ config })
            : undefined;
          // const onEditorConfirm = editable
          //   ? (e) => {
          //       const { field, newValue, rowData, originalEvent: event } = e;
          //       rowData[field] = newValue;
          //     }
          //   : undefined;
          const onEditorConfirm = (e) => {
            const { field, newValue, rowData, originalEvent: event } = e;
            rowData[field] = newValue;
          };

          // apply custom columns
          if (props.customColumns) {
            // let customColumn;
            for (const customColumn of props.customColumns) {
              if (customColumn.field === field) {
                bodyTemplate = customColumn.component;
                break;
              }
            }
          }

          if (!bodyTemplate) {
            switch (type) {
              case "number_plain":
              case "number_formatted":
              case "number_min_max":
              case "number_dropdown":
              case "number_radiobutton":
                bodyTemplate = (rowData) => {
                  const rowDataValue = extractDataFromMap({
                    key: field,
                    map: rowData,
                  });
                  let numberValue = rowDataValue;
                  if (options) {
                    for (const map of options) {
                      if (map.value === numberValue) {
                        numberValue = map.name;
                        break;
                      }
                    }
                  }

                  const minimumFractionDigits = config.min_fraction_digits ?? 0;
                  const maximumFractionDigits = config.max_fraction_digits ?? 0;
                  return numberValue
                    ? numberValue.toLocaleString("id-ID", {
                        maximumFractionDigits,
                        minimumFractionDigits,
                      })
                    : options
                    ? ""
                    : 0;
                };
                break;
              case "boolean_plain":
                bodyTemplate = (rowData) => {
                  const rowDataValue = extractDataFromMap({
                    key: field,
                    map: rowData,
                  });
                  return rowDataValue ? "Ya" : "Tidak";
                };
                break;
              case "date":
              case "date_date":
              case "date_time":
              case "date_datetime":
              case "date_min_max":
                bodyTemplate = (rowData) => {
                  const rowDataValue = extractDataFromMap({
                    key: field,
                    map: rowData,
                  });
                  if (rowDataValue && rowDataValue.length > 0) {
                    let refinedDateString = rowDataValue;
                    if (refinedDateString.length === 10) {
                      refinedDateString += " 00:00:00";
                    } else if (refinedDateString.length === 16) {
                      refinedDateString += ":00";
                    }
                    const parsedDate = parse(
                      refinedDateString,
                      "yyyy-MM-dd HH:mm:ss",
                      new Date()
                    );
                    return dateFnsFormat(parsedDate, format ?? "dd/MM/yyyy");
                  } else {
                    return "";
                  }
                };
                break;
              case "object":
                bodyTemplate = (rowData) => {
                  if (
                    rowData[object_name] &&
                    rowData[object_name][object_field] &&
                    rowData[object_name][object_field].length > 0
                  ) {
                    return rowData[object_name][object_field];
                  } else {
                    return "";
                  }
                };
                break;
              case "image":
                bodyTemplate = (rowData) => {
                  const rowDataValue = extractDataFromMap({
                    key: field,
                    map: rowData,
                  });
                  if (rowDataValue && rowDataValue !== null) {
                    return (
                      <img
                        src={`${process.env.REACT_APP_HOST_URL}/${rowDataValue}`}
                        alt={rowDataValue}
                        className="shadow-2 border-round"
                        style={{ width: "64px" }}
                      />
                    );
                  } else {
                    return "";
                  }
                };
                break;
              case "zirvu_file_manager":
                bodyTemplate = (rowData) => {
                  const rowDataValue = extractDataFromMap({
                    key: field,
                    map: rowData,
                  });
                  if (rowDataValue && rowDataValue !== null) {
                    return (
                      <img
                        src={`${rowDataValue}`}
                        alt={rowDataValue}
                        className="shadow-2 border-round"
                        style={{ width: "64px" }}
                      />
                    );
                  } else {
                    return "";
                  }
                };
                break;
              default:
                // if (editable) {
                //   editor = (options) => {
                //     return (
                //       <InputText
                //         type="text"
                //         value={options.value}
                //         onChange={(e) => options.editorCallback(e.target.value)}
                //       />
                //     );
                //   };
                // }
                bodyTemplate = (rowData) => {
                  const rowDataValue = extractDataFromMap({
                    key: field,
                    map: rowData,
                  });
                  let value = rowDataValue;
                  if (options) {
                    for (const map of options) {
                      if (map.value === value) {
                        value = map.name;
                        break;
                      }
                    }
                  }
                  return value;
                };
                break;
            }
          }

          return (
            <Column
              editor={editable ? (options) => editor(options) : undefined}
              onCellEditComplete={
                editable ? (e) => onEditorConfirm(e) : undefined
              }
              alignHeader={align}
              style={{ textAlign: align }}
              key={field}
              field={field}
              header={name}
              body={bodyTemplate}
            ></Column>
          );
        })}
      </DataTable>
    </div>
  );
};

export default TableAutoComponent;
