// import logo from "./logo.svg";
import "./App.css";

import idLocale from "./assets/locale/id.json";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { addLocale } from "primereact/api";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useContext, Suspense } from "react";
import UiContext from "./store/ui-context";

import ErrorPage from "./pages/error/ErrorPage";
import LoginPage from "./pages/login/LoginPage";

import LayoutDashboardPage from "./pages/dashboard/LayoutDashboardPage";
import LayoutDashboardUserPage from "./pages/dashboard-user/LayoutDashboardUserPage";

//import protector
import ProtectedRoute from "./pages/protected/ProtectedRoute";
import ProtectedRouteUser from "./pages/protected/ProtectedRouteUser";

//import admin page
import HomePage from "./pages/dashboard/default/HomePage";
import SignUpPage from "./pages/sign-up/SignUpPage";

//import user page
import UserHomePage from "./pages/dashboard-user/default/UserHomePage";
import SalesJournalPage from "./pages/dashboard/developer/transaction/SalesJournalPage";
import SettingPage from "./pages/dashboard/developer/setting/SettingPage";

// import reports

function App() {
  const uiContext = useContext(UiContext);

  const toastRef = useRef();

  useEffect(() => {
    uiContext.setToastRef(toastRef);
  }, [toastRef]);

  addLocale("id", idLocale.id);

  const MyRouter = (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/sign-up" element={<SignUpPage />}></Route>

        <Route
          // index
          path="/"
          element={
            <ProtectedRoute>
              <LayoutDashboardPage />
            </ProtectedRoute>
          }
        >
          <Route index element={<HomePage />}></Route>

          <Route path="developer">
            <Route path="setting" element={<SettingPage />} />
            <Route path="transaction" element={<SalesJournalPage />} />
          </Route>
        </Route>

        <Route
          // index
          path="user"
          element={
            <ProtectedRouteUser>
              <LayoutDashboardUserPage />
            </ProtectedRouteUser>
          }
        >
          <Route path="main" element={<UserHomePage />}></Route>
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );

  return (
    <>
      <Toast ref={toastRef} />
      {!toastRef.current && (
        <div className="col-center-center py-4">
          <h1> Initializing application ... </h1>{" "}
        </div>
      )}
      {toastRef.current && MyRouter}
    </>
  );
}

export default App;
