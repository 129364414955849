import { FaGears, FaReceipt } from "react-icons/fa6";

const MenuAdmin = [
  {
    label: "Setting",
    icon: <FaGears />,
    to: "developer/setting",
    children: [],
  },
  {
    label: "Transaction",
    to: "developer/transaction",
    icon: <FaReceipt />,
  },
];

export default MenuAdmin;
